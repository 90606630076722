<template>
  <b-sidebar
    id="sidebar-create-edit"
    ref="sidebar-create-edit"
    bg-variant="white"
    right
    backdrop
    shadow
    :title="this.dataModules.id == null ? 'Cadastrar Agencia' : 'Editar Agencia'"
  >
  <div>
    <!-- Codigo -->
    <b-form-group
      label="* Codigo:"
      label-for="i-code"
      class="pl-1 pr-1"
    >
      <b-form-input
        id="i-code"
        type="text"
        placeholder="Ex: 10"
        v-model="dataModules.codigo"
      />
    </b-form-group>
    <!-- Name -->
    <b-form-group
      label="* Nome:"
      label-for="i-nome"
      class="pl-1 pr-1"
    >
      <b-form-input
        id="i-nome"
        type="text"
        placeholder="Ex: Centro"
        v-model="dataModules.nome"
      />
    </b-form-group>
    <!-- Porte -->
    <b-form-group
      label="* Porte:"
      label-for="i-Porte"
      class="pl-1 pr-1"
    >
      <b-form-select
        class="form-content"
        v-model="dataModules.porte"
        :options="porteOptions"
        />
    </b-form-group>
		<!-- Regional -->
    <b-form-group
      label="* Regional:"
      label-for="i-regional"
      class="pl-1 pr-1"
    >
			<b-form-select
				id="i-regional"
				v-model="dataModules.regional_id"
				:options="modules.regionais"
				value-field="id"
				text-field="nome"
				class="form-control"
			/>
    </b-form-group>

    <b-row class="ml-1 mr-1 pb-1">
      <b-button variant="relief-primary" block @click="storeAndUpdate">
        {{this.dataModules.id == null ? 'Salvar' : 'Editar'}}
      </b-button>
    </b-row>
    <b-row class="ml-1 mr-1 pb-1">
      <b-button variant="relief-danger" block @click="initValues">
        Limpar
      </b-button>
    </b-row>
  </div>
    
  </b-sidebar>
</template>
<script>
/* eslint-disable */
import { VBToggle } from 'bootstrap-vue'
import { mapActions, mapState } from "vuex";
import Ripple from "vue-ripple-directive";
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default {
   props: { 
    dataProp: {
      type: Object,
      default: () => {}
    },
  },
  components: {
    mapActions,
    mapState
  },
  directives: {
    'b-toggle': VBToggle,
    Ripple
  },
  data(){
    return {
      // Variáveis Global

      dataModules: {
        nome: null,
        id: null,
        codigo: null,
        porte: 'S2',
        regional_id: null,
        pathAPI: 'agencias'
      },

      porteOptions: [
        { value: 'S1', text: "S1" },
        { value: 'S2', text: "S2" },
        { value: 'S3', text: "S3" },
      ],
    }
  },
  computed: {
    ...mapState("connectAPI", ['modules'])
  },
  methods: {
    ...mapState("connectAPI", ["store", "update"]),

    initValues(){
      this.dataModules.id = null,
      this.dataModules.nome = null,
			this.dataModules.codigo = null,
			this.dataModules.porte = null,
			this.dataModules.regional_id = null
      
    },

		fetchModules(){
      this.isLoading = true
      this.$store
        .dispatch("connectAPI/fetchModules", {
          pathAPI: 'regionais',
          paginated: false
        })
        .then((response) => {
        })
        .catch((err) => {
          console.error(err)
        })
        .finally(() => {
          this.isLoading = false
        })
    },

    storeAndUpdate() {
      if (this.dataModules.id == null) { 
        this.$store.dispatch("connectAPI/store", {
          data: this.dataModules
        })
        .then(() => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Cadastrado com sucesso!',
              icon: 'ThumbsUpIcon',
              text: 'Operação executada com sucesso.',
              variant: 'success'
            }
          })
          this.initValues()
        })
        .finally(() => {
          this.$emit('fetch-all');
          this.closeSidebar()
        })
      } else {
        this.$store.dispatch("connectAPI/update", {
          data: this.dataModules
        })
        .then(() => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Atualizado com sucesso!',
              icon: 'ThumbsUpIcon',
              text: 'Operação executada com sucesso.',
              variant: 'info'
            }
          })
          this.initValues();
        })
        .finally(() => {
          this.$refs['sidebar-create-edit'].hide()
          this.$emit('fetch-all');
        })
      }
    },

    closeSidebar(){
      this.$refs['sidebar-create-edit'].hide()
    }
  },
  watch: {
    dataProp: {
      immediate: true, 
      handler (val, oldVal) {
        if (!val) return; 
          if (this.dataProp.id == null) { 
            this.initValues(); 
          } else { 
            this.dataModules.id = this.dataProp.id;
            this.dataModules.nome = this.dataProp.nome;
            this.dataModules.codigo = this.dataProp.codigo;
            this.dataModules.porte = this.dataProp.porte;
            this.dataModules.regional_id = this.dataProp.regional_id;
          } 
      }
    },
  },
  created(){
    this.fetchModules();
  }
}
</script>